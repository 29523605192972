import React from "react"
import hmacSHA256 from "crypto-js/hmac-sha256"
import Base64 from "crypto-js/enc-base64"

import ToolLayout from "../../components/layout/tool"

export default class Welcome extends React.Component {
  constructor(props) {
    super(props)
    this.state = { nonce: "", secret: "", request: "" }
  }

  render() {
    var trimRequest = () => {
      return this.state.request
        .replace(/[\n\r]+/g, "")
        .replace(/["';@&$#!^*()%_+~`?<>\[\]]/g, "")
    }

    var hashedRequest = () => {
      return Base64.stringify(
        hmacSHA256(
          trimRequest().toUpperCase() + ":" + this.state.nonce,
          this.state.secret
        )
      )
    }

    const DisplayField = ({ value, children }) => {
      if (
        this.state.request === "" ||
        this.state.nonce === "" ||
        this.state.secret === ""
      ) {
        return (
          <p className="text-muted">
            Please supply the required details first.
          </p>
        )
      }
      return (
        <textarea
          className="form-control"
          rows="4"
          value={value}
          disabled
        ></textarea>
      )
    }

    return (
      <ToolLayout title="LiquidNet Signature Generator">
        <div className="headline">
          <h1 className="text-primary m-0">Liquidnet Signature Generator</h1>
        </div>
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 my-2 text-primary">Getting Started</h2>
            <p className="text-muted">
              Details listed below is required for generating signature
            </p>
            <code>
              <ul>
                <li>Request body (JSON)</li>
                <li>Nonce</li>
                <li>Secret</li>
              </ul>
            </code>
          </div>
          <div className="col-md-8">
            <form>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="text-dark" htmlFor="requestBody">
                    Request Body
                  </label>
                  <textarea
                    id="requestBody"
                    className="form-control"
                    rows="5"
                    placeholder="Request body in JSON format"
                    onChange={t => this.setState({ request: t.target.value })}
                    value={this.state.request}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-10">
                  <label htmlFor="nonce">Nonce</label>
                  <div className="input-group">
                    <input
                      id="nonce"
                      type="text"
                      className="form-control"
                      placeholder="Nonce"
                      onChange={t => this.setState({ nonce: t.target.value })}
                      value={this.state.nonce}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="nonce-generate"
                        onClick={() =>
                          this.setState({
                            nonce: Math.round(new Date().getTime() / 1000),
                          })
                        }
                      >
                        Refresh Nonce
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="text-dark" htmlFor="secret">
                    Secret
                  </label>
                  <input
                    id="secret"
                    type="text"
                    className="form-control"
                    placeholder="Secret Value"
                    onChange={t => this.setState({ secret: t.target.value })}
                    value={this.state.secret}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr />
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 text-primary my-2">Step 1</h2>
            <p className="text-muted">
              Trim the request body so it only contains
            </p>
            <code>
              <ul>
                <li>alphanumeric</li>
                <li>{"{}"}</li>
                <li>:</li>
                <li>,</li>
                <li>.</li>
              </ul>
            </code>
          </div>
          <div className="col-md-8">
            <DisplayField value={trimRequest()} />
          </div>
        </div>

        <hr />
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 text-primary my-2">Step 2</h2>
            <p className="text-muted">Uppercase the result from step 1</p>
          </div>
          <div className="col-md-8">
            <DisplayField value={trimRequest().toUpperCase()} />
          </div>
        </div>

        <hr />
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 text-primary my-2">Step 3</h2>
            <p className="text-muted">
              Add <code>timestamp</code> to the result from step 2
            </p>
          </div>
          <div className="col-md-8">
            <DisplayField
              value={trimRequest().toUpperCase() + ":" + this.state.nonce}
            />
          </div>
        </div>

        <hr />
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 text-primary my-2">Step 4</h2>
            <p className="text-muted">
              Compute <code>HMAC-SHA256</code> of the result from step 3 and
              encode the result with <code>base64</code>
            </p>
          </div>
          <div className="col-md-8">
            <DisplayField value={hashedRequest()} />
          </div>
        </div>

        <hr />
        <div className="row py-3">
          <div className="col-md-4">
            <h2 className="h5 text-primary my-2">Step 5</h2>
            <p className="text-muted">
              Form the signature header using{" "}
              <code>t=timestamp,signature_value</code>
            </p>
          </div>
          <div className="col-md-8">
            <DisplayField
              value={"t=" + this.state.nonce + "," + hashedRequest()}
            />
          </div>
        </div>
      </ToolLayout>
    )
  }
}
